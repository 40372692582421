import {DevPortalProvider} from '@stoplight/elements-dev-portal';
import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Navigation} from './components/Navigation';
import {PublicRouter} from "./components/PublicRouter";
import {PrivateRouter} from "./components/PrivateRouter";
import {MobileModal} from "./components/MobileModal";

class App extends Component {
  render() {
    const publicMode = process.env.REACT_APP_PUBLIC_MODE === 'true';

    return (
      <DevPortalProvider>
        <BrowserRouter>
          <div className="app-container">
            <header>
              {!publicMode && <Navigation/>}
            </header>
            <main className="main-content">
              {publicMode ? <PublicRouter/> : <PrivateRouter/>}
            </main>
            <MobileModal/>
          </div>
        </BrowserRouter>
      </DevPortalProvider>
    );
  }
}

export default App;
