import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {PublicPartnerAPI3, PublicPublisherAPI3} from "./API";
import {NotFound} from "./NotFound";

export const PublicRouter = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to="/public/v3/partners-api"/>
    </Route>

    <Route path="/public/v3/partners-api" component={PublicPartnerAPI3}/>
    <Route path="/public/v3/publishers-api" component={PublicPublisherAPI3}/>

    <Route component={NotFound}/>
  </Switch>
);
